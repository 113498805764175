<template>
  <div>
    <v-banner class="mb-5">
      <span class="font-weight-black text-h5">DASHBOARD</span>

      <span class="text-h5">
        <i> - Integreted Database Aparatur Sipil Negara</i>
      </span>

      <template v-slot:actions>
        <v-alert color="primary" dense dark icon="mdi-calendar-blank">
          {{ tglFormat(Date.now()) }}
        </v-alert>
      </template>
    </v-banner>

    <v-row v-if="session">
      <v-col cols="12" md="9">
        <v-alert
          border="left"
          color="primary"
          colored-border
          elevation="1"
          class="mb-5"
        >
          <span>
            SELAMAT DATANG - <b>{{ session.nama.toUpperCase() }}</b>
          </span>
        </v-alert>

        <v-card class="pa-3">
          <v-row>
            <v-col cols="12" md="3">
              <v-card
                outlined
                :to="
                  '/' + session.status_asn.toLowerCase() + '/' + session.nipBaru
                "
              >
                <div class="text-center pa-3" style="background-color: #eeeeee">
                  <v-icon size="150" color="amber">
                    mdi-card-account-details
                  </v-icon>
                </div>

                <div class="pa-3 text-center">
                  <h3>Data Personal</h3>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card outlined>
                <div class="text-center pa-3" style="background-color: #eeeeee">
                  <v-icon size="150" color="amber"> mdi-list-box </v-icon>
                </div>

                <div class="pa-3 text-center">
                  <h3>Daftar Riwayat Hidup</h3>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card outlined>
                <div class="text-center pa-3" style="background-color: #eeeeee">
                  <v-icon size="150" color="amber"> mdi-pencil-circle </v-icon>
                </div>

                <div class="pa-3 text-center">
                  <h3>Update Data Mandiri</h3>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card outlined>
                <div class="text-center pa-3" style="background-color: #eeeeee">
                  <v-icon size="150" color="amber"> mdi-information </v-icon>
                </div>

                <div class="pa-3 text-center">
                  <h3>Reminder / Daftar Jaga</h3>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-divider class="my-5"></v-divider>

        <div class="text-center">
          <h2 class="font-weight-thin">
            Selamat datang di layanan <strong>IDASN</strong>. Silahkan pilih
            menu Layanan yang ada pada sidebar dibagian kiri Aplikasi.
          </h2>
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <!-- <v-img src="@/assets/gambar/no_pic_male.png"></v-img> -->
        <v-img :src="foto"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
export default {
  data: () => ({
    token: Cookie.get("token"),
    session: "",
    foto: "",
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      const url = process.env.VUE_APP_ASN + "photos/" + this.session.nipBaru;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((response) => {
          this.foto = URL.createObjectURL(response.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },
  },
};
</script>
